<div class="cont">
    <H2 class="head">{{'seminars.head'|translate}}</H2>

    <p [innerHTML]="'seminars.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/15-02-24/15-02-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/15-02-24/15-02-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/15-02-24/15-02-24-3.png" alt="" width="500" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'seminars.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/07-02-24/07-02-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/07-02-24/07-02-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/07-02-24/07-02-24-3.png" alt="" width="500" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'seminars.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/02-02-24/02-02-24-1.png" alt="" width="400" height="500">
        <img src="assets/components/seminary/02-02-24/02-02-24-2.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'seminars.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/31-01-24/31-01-24-1.png" alt="" width="400" height="500">
        <img src="assets/components/seminary/31-01-24/31-01-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/31-01-24/31-01-24-3.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'seminars.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/19-01-24/19-01-24-2.png" alt="" width="400" height="500">
        <img src="assets/components/seminary/19-01-24/19-01-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/seminary/19-01-24/19-01-24-3.png" alt="" width="500" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'seminars.text5' | translate"></p>
    <div class="display-row">
        <img src="assets/components/seminary/14-11-23/14-11-23-1.png" alt="" width="600" height="500">
        <img src="assets/components/seminary/14-11-23/14-11-23-2.png" alt="" width="600" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>
</div>
<docs-table [translations]="translations"></docs-table>
