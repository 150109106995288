import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FloatLabelType, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Mail } from 'src/app/model/mail';
import { MailService } from 'src/app/services/mail.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'contact-form-component',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.sass']
})
export class ContactFormComponent {
  mailForm: Mail = {
    name: '',
    mail: '',
    message: '',
  };

  requestControl = new FormGroup({
    name: new FormControl(this.mailForm.name, Validators.required),
    mail: new FormControl(this.mailForm.mail, Validators.email),
    message: new FormControl(this.mailForm.message),
  });
  floatLabelControl = new FormControl('never' as FloatLabelType);

  constructor(
    private mailS: MailService
    ){}

  ngOnInit(): void {
  }

  getFloatLabelValue(): FloatLabelType {
    return this.floatLabelControl.value || 'auto';
  }

  getErrorMessage() {
  }

  submit(){
    this.mailForm = {
      name: this.requestControl.value.name ?? '',
      mail: this.requestControl.value.mail ?? '',
      message: this.requestControl.value.message ?? ''
    };
    this.mailS.sendMail(this.mailForm)
  }

}
