<div class="video-wrapper">
  <div class="intro">
    <h2>{{'main-page.video.name' | translate}}</h2>
  </div>
  <div class="cont">
    <div class="content">
      <owl-carousel-o [options]="customOptions">

        <ng-container *ngFor="let slide of slides">
          <ng-template carouselSlide>
            <youtube-player
            [width]="1250"
            [height]="500"
            [videoId]="slide"
          ></youtube-player>
          </ng-template>
        </ng-container>

      </owl-carousel-o>
    </div>
  </div>
</div>
