<div class="contact-form-wrapper">
  <div class="intro">
    <h2>{{'main-page.contact-form.header' | translate}}</h2>
  </div>
  <div class="cont form">
    <div class="main-inputs" [formGroup]="requestControl">
      <div class="input-header">{{'main-page.contact-form.question' | translate}}</div>
      <div class="little-inputs">
        <mat-form-field [floatLabel]="getFloatLabelValue()">
          <input matInput placeholder="{{'main-page.contact-form.name'| translate}}" formControlName="name" required/>
        </mat-form-field>
        <mat-form-field [floatLabel]="getFloatLabelValue()">
          <input matInput placeholder="{{'main-page.contact-form.mail'| translate}}" formControlName="mail" required/>
        </mat-form-field>
      </div>
      <mat-form-field [floatLabel]="getFloatLabelValue()">
        <textarea matInput placeholder="{{'main-page.contact-form.message'| translate}}" formControlName="message"></textarea>
      </mat-form-field>
      <button mat-raised-button class="blue-button" [disabled]="!requestControl.valid" (click)="submit()">
        {{'main-page.contact-form.send'|translate}}
      </button>
    </div>
    <div class="side-text">
      <h2 [innerHTML]="'main-page.contact-form.side-text.branch' | translate"></h2>

      <span><br>{{'main-page.contact-form.side-text.address-1' | translate}}</span>
      <span><a href='tel:8(017) 396-36-25'>8(017) 396-36-25</a></span>
      <span>{{'main-page.contact-form.side-text.fax' | translate}} <a href='tel:8(017) 272-38-92'>8(017) 272-38-92</a></span>
      <span><a href='mailto:director@college-ripo.by'>director@college-ripo.by</a></span>

      <span><br>{{'main-page.contact-form.side-text.schedule' | translate}}</span>

      <br>
      <h2 [innerHTML]="'main-page.contact-form.side-text.institute' | translate"></h2>

      <span><br>{{'main-page.contact-form.side-text.address-2' | translate}}</span>
      <span>{{'main-page.contact-form.side-text.phone-fax' | translate}} <a href='tel:8(017) 215-09-92'>8(017) 215-09-92</a></span>
      <span><a href='mailto:master@ripo.unibel.by'>master@ripo.unibel.by</a></span>
      <span><a href='pom.rektora.ripo@gmail.com'>pom.rektora.ripo@gmail.com</a></span>
    </div>
  </div>
</div>
