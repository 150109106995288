<div class="banner-wrapper">
  <div class="cont">
    <div class="banner">
      <img src="assets/logo.png" alt="" class="logo-img">
      <p [innerHTML]="'main-page.banner-text.text' | translate"></p>
      <img src="assets/banner.png" class="banner-img" alt="">
      <!-- <div class="relative-img">
      </div> -->
    </div>
  </div>
</div>
