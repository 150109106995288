import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Administrator } from 'src/app/model/administration';

@Component({
  selector: 'app-administration',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.sass']
})
export class AdministrationComponent {
  administrators: Administrator[]

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
  }

  ngOnInit() {
    this.loadTranslations();
  }

  loadTranslations() {
    this.translate.get('admins.list').subscribe((res: any) => {
      this.administrators = res;
    });
  }
}
