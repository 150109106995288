import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { IntroBannerModule } from '../intro-banner/intro-banner.module';
import { CollegeModule } from '../college/college.module';
import { NewsSearchComponent } from '../news-search/news-search.component';
import { VideoComponent } from '../video/video.component';
import { TraineeComponent } from '../trainee/trainee.component';
import { AdditionalEduComponent } from '../additional-edu/additional-edu.component';
import { SideMenuComponent } from '../../side-menu/side-menu.component';
import { InnerCarouselComponent } from '../../inner-carousel/inner-carousel.component';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { PartnersCarouselModule } from '../partners-carousel/partners-carousel.module';
import { UsefulLinksModule } from '../useful-links/useful-links.module';
import { LocationMapComponent } from '../location-map/location-map.component';

@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    CommonModule,
    IntroBannerModule,
    CollegeModule,
    PartnersCarouselModule,
    NewsSearchComponent,
    LocationMapComponent,
    ContactFormComponent,
    VideoComponent,
    TraineeComponent,
    AdditionalEduComponent,
    SideMenuComponent,
    InnerCarouselComponent,
    UsefulLinksModule
  ],
  exports:[MainComponent],
})
export class MainModule { }
