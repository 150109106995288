import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-certificates',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.sass']
})
export class CertificatesComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'certificates';
}
