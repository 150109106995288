import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Slide } from 'src/app/model/slide';

@Component({
  selector: 'inner-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inner-carousel.component.html',
  styleUrls: ['./inner-carousel.component.sass']
})
export class InnerCarouselComponent {
  @Input() slides: Slide[] = [];

  currentIndex: number = 0;
  timeoutId?: number;

  ngOnInit(): void {
    this.resetTimer();
  }
  ngOnDestroy() {
    window.clearTimeout(this.timeoutId);
  }
  resetTimer() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 3000);
  }

  goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
    this.resetTimer();
    this.currentIndex = slideIndex;
  }

  getCurrentSlideUrl() {
      return this.slides[this.currentIndex].path;
  }
}
