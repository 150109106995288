<div class="cont">
    <H2 class="head">{{'legal-corner.head'|translate}}</H2>


<docs-table [translations]="translations"></docs-table> 
<div class="cont">
    <p class="head1" [innerHTML]="'legal-corner.text' | translate"></p>
</div>
    <div class="display-row">
        <img src="assets/components/corner/narkotiki.png" alt="Фотография" width="1000" height="700">
        <img src="assets/components/corner/narkoshop.png" alt="Фотография" width="1000" height="700">
    </div>
    <div class="display-row">
        <img src="assets/components/corner/narkotiki-1.png" alt="" width="600" height="700">
        <img src="assets/components/corner/narkotiki-2.png" alt="" width="600" height="700">
    </div>
</div>
<div class="cont">
     <p  [innerHTML]="'legal-corner.text1' | translate"></p>
     <div class="display-row">
        <img src="assets/components/corner/stop-nasilie.png" alt="" width="700" height="700">
        <img src="assets/components/corner/nasilie.png" alt="" width="700" height="700">
    </div>
</div>
<div class="cont">
    <p [innerHTML]="'legal-corner.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/corner/fishing.png" alt="">
        <img src="assets/components/corner/haker.png" alt="">
   </div>
   <p [innerHTML]="'legal-corner.text3' | translate"></p>
   <div class="display-row">
        <img src="assets/components/corner/igromanija-2.png" alt="">
        <img src="assets/components/corner/pornografija.png" alt="">
    </div>
</div>
    
      


