import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from '../../docs-table/docs-table.component';

@Component({
  selector: 'app-hostel',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './hostel.component.html',
  styleUrls: ['./hostel.component.sass']
})
export class HostelComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'hostel';
}
