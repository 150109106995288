import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from '../../docs-table/docs-table.component';

@Component({
  selector: 'app-pto',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './pto.component.html',
  styleUrls: ['./pto.component.sass']
})
export class PTOComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'pto';
}
