import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatExpansionModule],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent {

  constructor(
    private router: Router,
  ){
  }

  openSite(path: string){
    window.open(path, "_blank")
  }

  navigateTo(path: string){
    this.router.navigate([path])
  }
}

