<div class="cont">
    <H2 class="head">{{'announcement.head'|translate}}</H2>
    <docs-table [translations]="translations"></docs-table> 
    <p class="head2" [innerHTML]="'announcement.text' | translate"></p>
    <p class="head" [innerHTML]="'announcement.text1' | translate"></p>
    <p class="head" [innerHTML]="'announcement.text2' | translate"></p>
    <p class="head2" [innerHTML]="'announcement.text3' | translate"></p>
    <p class="head" [innerHTML]="'announcement.text4' | translate"></p>
</div>  
<docs-table [translations]="translations"></docs-table> 
