import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-local-acts',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './local-acts.component.html',
  styleUrls: ['./local-acts.component.sass']
})
export class LocalActsComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'local-acts';
}
