<div class="footer-wrapper">
  <div class="cont">
    <div class="content">
      <div class="first-row">
        <div class="ripo" [innerHTML]="'footer.ripo'|translate"></div>
        <div class="contact">
          {{'footer.contact'|translate}}
          <br><br>
          <a href='tel:8(017)396-36-25'>8(017)396-36-25</a>
            <br>
          <a href='mailto:director@college-ripo.by'>director@college-ripo.by</a>
        </div>
        <div class="media">
          <div class="subscribe">{{'footer.subscribe' | translate}}</div>
          <div class="icons">
            <div class="icon">
              <a href="https://m.vk.com/college.ripoby">
                <img src="assets/vk.png" alt="">
              </a>
            </div>
            <div class="icon">
              <a href="https://t.me/kstmia_uo_ripo">
                <img src="assets/telegram.png" alt="">
              </a>
            </div>
            <div class="icon">
              <a href="https://instagram.com/kstmia_uo_ripo?igshid=YmMyMTA2M2Y=">
                <img src="assets/instagram.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="second-row">{{'footer.copyright'|translate}}</div>
      <div class="third-row" [innerHTML]="'footer.registration'|translate"></div>
    </div>
  </div>
</div>
