import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextDirective } from './next.directive';
import { PrevDirective } from './prev.directive';
import { PartnersCarouselComponent } from './partners-carousel.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PartnersCarouselComponent, NextDirective, PrevDirective],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [PartnersCarouselComponent]
})
export class PartnersCarouselModule { }
