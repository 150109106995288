import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalEduCardComponent } from '../additional-edu-card/additional-edu-card.component';
import { Card } from 'src/app/model/card';

@Component({
  selector: 'additional-edu-component',
  standalone: true,
  imports: [CommonModule, TranslateModule, AdditionalEduCardComponent],
  templateUrl: './additional-edu.component.html',
  styleUrls: ['./additional-edu.component.sass']
})
export class AdditionalEduComponent {
  cards: Card[];

  constructor(private translate: TranslateService) {
    this.loadCards();
    this.translate.onLangChange.subscribe(() => {
      this.loadCards();
    });
  }
  loadCards() {
    this.translate.get('main-page.additional-edu.cards').subscribe((res: any) => {
      this.cards = res;
    });
  }
}
