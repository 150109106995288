<div class="cont">
    <H2 class="head">{{'travel.head'|translate}}</H2>
    <p [innerHTML]="'travel.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Travel/10-2022-1.png" alt="" width="800" height="600">
        <img src="assets/components/Travel/10-2022-2.png" alt="" width="800" height="600">
        <img src="assets/components/Travel/10-2022-8.png" alt="" width="800" height="600">
    </div>
    <div class="display-row">

        <img src="assets/components/Travel/10-2022-7.png" alt="" width="500" height="600">
        <img src="assets/components/Travel/10-2022-3.png" alt="" width="800" height="600">
    </div>
</div>  
<docs-table [translations]="translations"></docs-table> 
