<div class="cont">
    <H2 class="head">{{'news.head'|translate}}</H2>
</div>  
    <docs-table [translations]="translations"></docs-table>
<div class="cont">





    <p [innerHTML]="'news.text34' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/02-05-2024-1.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/02-05-2024-2.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/02-05-2024-3.jpg" alt="Фотография" width="600" height="600">
    </div>

    <p [innerHTML]="'news.text33' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/18-04-2024-1.jpg" alt="Фотография" width="400" height="600">
        <img src="assets/components/News/18-04-2024-2.jpg" alt="Фотография" width="400" height="600">
        <img src="assets/components/News/18-04-2024-3.jpg" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text32' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/13-04-2024-1.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/13-04-2024-2.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/13-04-2024-3.jpg" alt="Фотография" width="600" height="600">
    </div>

    <p [innerHTML]="'news.text31' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/11-04-2024-1.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/11-04-2024-2.jpg" alt="Фотография" width="400" height="600">
        <img src="assets/components/News/11-04-2024-3.jpg" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text30' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/09-04-2024-1.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/09-04-2024-2.jpg" alt="Фотография" width="600" height="600">
        <img src="assets/components/News/09-04-2024-3.jpg" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text29' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/08-04-2024-1.jpg" alt="Фотография" width="700" height="600">
        <img src="assets/components/News/08-04-2024-2.jpg" alt="Фотография" width="400" height="600">
        <img src="assets/components/News/08-04-2024-3.jpg" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text27' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/zero-2024.jpg" alt="Фотография" width="800" height="800">
    </div>
    <p [innerHTML]="'news.text28' | translate"></p>

    <p [innerHTML]="'news.text26' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/15-03-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/15-03-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/15-03-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text25' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/14-03-2024-1.jpg" alt="Фотография" width="1000" height="500">
    </div>

    <p [innerHTML]="'news.text24' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/13-03-2024-1.jpg" alt="Фотография" width="1000" height="500">
    </div>

    <p [innerHTML]="'news.text23' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/12-03-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/12-03-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/12-03-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text22' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/11-03-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/11-03-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/11-03-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text21' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/06-03-2024-1.jpg" alt="Фотография" width="500" height="500">
        <img src="assets/components/News/06-03-2024-2.jpg" alt="Фотография" width="500" height="500">
        <img src="assets/components/News/06-03-2024-3.jpg" alt="Фотография" width="500" height="500">
    </div>

    <p [innerHTML]="'news.text20' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/05-03-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/05-03-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/05-03-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text19' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/16-02-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/16-02-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/16-02-2024-3.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text18' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/15-02-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/15-02-2024-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/15-02-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text17' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/14-02-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/14-02-2024-2.jpg" alt="Фотография" width="500" height="500">
        <img src="assets/components/News/14-02-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text16' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/13-02-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/13-02-2024-2.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text15' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/12-02-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/12-02-2024-2.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text14' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/02-02-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/02-02-2024-2.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text13' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/31-01-2024-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/31-01-2024-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/31-01-2024-3.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text12' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/12-01-2024-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/12-01-2024-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/12-01-2024-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text11' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/27-12-2023-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/27-12-2023-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text10' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/20-12-2023-1.jpg" alt="Фотография" width="300" height="500">
        <img src="assets/components/News/20-12-2023-2.jpg" alt="Фотография" width="300" height="500">
    </div>

    <p [innerHTML]="'news.text9' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/19-12-2023-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/19-12-2023-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/19-12-2023-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text8' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/17-11-2023-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/17-11-2023-2.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text7' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/14-11-2023-2-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/14-11-2023-2-2.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text6' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/14-11-2023-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/14-11-2023-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/14-11-2023-3.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text5' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/31-10-2023-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/31-10-2023-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/31-10-2023-3.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/26-10-2023-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/26-10-2023-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/26-10-2023-3.jpg" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/25-10-2023-1.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/25-10-2023-2.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/25-10-2023-3.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/25-10-2023-4.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/16-10-2023-1.jpg" alt="Фотография" width="600" height="500">
        <img src="assets/components/News/16-10-2023-2.jpg" alt="Фотография" width="400" height="500">
        <img src="assets/components/News/16-10-2023-3.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/22-09-2023-1.jpg" alt="Фотография" width="500" height="500">
        <img src="assets/components/News/22-09-2023-2.jpg" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News/21.09.2023.png" alt="Фотография" width="1000" height="800">
    </div>
</div>