<div class="cont">
    <H2 class="head">{{'abcsafety.head'|translate}}</H2>
    <docs-table [translations]="translations"></docs-table> 
    <p [innerHTML]="'abcsafety.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/abc/26-05-2022/26-05-2022-1.png" alt="" width="500" height="500">
        <img src="assets/components/abc/26-05-2022/26-05-2022-2.png" alt="" width="500" height="500">
        <img src="assets/components/abc/26-05-2022/26-05-2022-3.png" alt="" width="500" height="500">
        <img src="assets/components/abc/26-05-2022/26-05-2022-4.png" alt="" width="500" height="500">
    </div>
    <img src="assets/components/123.png" alt="" width="1500" height="50">
    <H2 class="head1">{{'abcsafety.head1'|translate}}</H2>
    <H2 class="head2">{{'abcsafety.head2'|translate}}</H2>
    <p [innerHTML]="'abcsafety.text1' | translate"></p>
    <p [innerHTML]="'abcsafety.text2' | translate"></p>
    <p [innerHTML]="'abcsafety.text3' | translate"></p>
    <p [innerHTML]="'abcsafety.text4' | translate"></p>
</div>  


