<div class="banner">
  <app-side-menu></app-side-menu>
  <div class="column">
    <inner-carousel [slides]="slides"></inner-carousel>
    <news-search-component></news-search-component>
  </div>
</div>
<college-component></college-component>
<video-component></video-component>
<trainee-component></trainee-component>
<location-map-component></location-map-component>
<!-- <contact-form-component></contact-form-component> -->
<additional-edu-component></additional-edu-component>
<partners-carousel-component></partners-carousel-component>
<app-useful-links></app-useful-links>
