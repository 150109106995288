import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-spps-rod',
  standalone: true,
  imports: [CommonModule, DocsTableComponent,TranslateModule],
  templateUrl: './spps-rod.component.html',
  styleUrls: ['./spps-rod.component.sass']
})
export class SppsRodComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'spps-rod';
}
