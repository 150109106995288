<div class="admins-wrapper">
  <div class="cont">
    <h2>{{'admins.banner' | translate}}</h2>
    <div class="admins-grid" *ngIf="administrators">
      <ng-container *ngFor="let admin of administrators">
        <div class="admin-card">
          <img src="{{admin.photoUrl}}" alt="" class="img-fluid admin-photo">
          <span class="admin-name">{{admin.name}}</span>
          <span class="admin-position">{{admin.jobPosition}}</span>
            <div class="prop" *ngIf="admin.mail">{{'admins.mail' | translate}}
              <a href="mailto:{{admin.mail}}">{{admin.mail}}</a>
            </div>
            <div class="prop" *ngIf="admin.phone">{{'admins.phone' | translate}}
              <a href="tel:{{admin.phone}}">{{admin.phone}}</a>
            </div>
          <div class="specialties" *ngIf="admin.specialties.length > 0">
            {{'admins.specialties' | translate}}
            <div class="admin-specialties" *ngFor="let spec of admin.specialties">
              ● {{spec.number}} - 
              {{spec.name}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
