import { Component } from '@angular/core';

@Component({
  selector: 'college-component',
  templateUrl: './college.component.html',
  styleUrls: ['./college.component.sass']
})
export class CollegeComponent {

}
