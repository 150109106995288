<div class="location-map-wrapper">
  <div class="intro">
    <h2>{{'main-page.location-map.header' | translate}}</h2>
  </div>
  <div class="cont form">
    <iframe src="https://yandex.com/map-widget/v1/?um=constructor%3A074b138a4dc0f6fe789401ede9ff128103529b20ce1883523068eba695e172cd&amp;source=constructor" width="100%" height="450px" frameborder="0"></iframe>
    <div class="side-text">
      <h2 [innerHTML]="'main-page.contact-form.side-text.branch' | translate"></h2>

      <span><br>{{'main-page.contact-form.side-text.address-1' | translate}}</span>
      <span><a href='tel:8(017) 396-36-25'>8(017) 396-36-25</a></span>
      <span>{{'main-page.contact-form.side-text.fax' | translate}} <a href='tel:8(017) 272-38-92'>8(017) 272-38-92</a></span>
      <span><a href='mailto:director@college-ripo.by'>director@college-ripo.by</a></span>

      <span><br>{{'main-page.contact-form.side-text.schedule' | translate}}</span>

      <br>
      <h2 [innerHTML]="'main-page.contact-form.side-text.institute' | translate"></h2>

      <span><br>{{'main-page.contact-form.side-text.address-2' | translate}}</span>
      <span>{{'main-page.contact-form.side-text.phone-fax' | translate}} <a href='tel:8(017) 215-09-92'>8(017) 215-09-92</a></span>
      <span><a href='mailto:master@ripo.unibel.by'>master@ripo.unibel.by</a></span>
      <span><a href='pom.rektora.ripo@gmail.com'>pom.rektora.ripo@gmail.com</a></span>
    </div>
  </div>
</div>
