<div class="cont">
    <H2 class="head">{{'prof-sotr.head'|translate}}</H2>
    <p class="head" [innerHTML]="'prof-sotr.text' | translate"></p>

<docs-table [translations]="translations"></docs-table>



    <p class="head" [innerHTML]="'prof-sotr.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/profcom/19-01-2023-1.png" alt="Фотография" width="500" height="300">
        <img src="assets/components/profcom/19-01-2023-2.png" alt="Фотография" width="500" height="300">
        <img src="assets/components/profcom/19-01-2023-3.png" alt="Фотография" width="400" height="300">
        <img src="assets/components/profcom/19-01-2023-4.png" alt="Фотография" width="500" height="300">
        <img src="assets/components/profcom/19-01-2023-5.png" alt="Фотография" width="500" height="300">
    </div>
</div>