<div class="cont">
    <H2 class="head">{{'hostel.head'|translate}}</H2>
    <p class="head" [innerHTML]="'hostel.text' | translate"></p>

<docs-table [translations]="translations"></docs-table>



    <div class="display-row">
        <img src="assets/components/hostel/1.jpg" alt="Фотография" width="700" height="700">
        <img src="assets/components/hostel/2.jpg" alt="Фотография" width="700" height="700">
        <img src="assets/components/hostel/5.jpg" alt="Фотография" width="700" height="700">
    </div>
    <div class="display-row">
        <img src="assets/components/hostel/8.jpg" alt="Фотография" width="700" height="500">
        <img src="assets/components/hostel/4.jpg" alt="Фотография" width="700" height="500">

    </div>
    <div class="display-row">
        <img src="assets/components/hostel/7.jpg" alt="Фотография" width="700" height="500">
        <img src="assets/components/hostel/3.jpg" alt="Фотография" width="700" height="500">

    </div>
</div>
