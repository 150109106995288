import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from '../../docs-table/docs-table.component';

@Component({
  selector: 'app-mspo',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './mspo.component.html',
  styleUrls: ['./mspo.component.sass']
})
export class MSPOComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'mspo';
}
