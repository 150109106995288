<div class="cont">
    <H2 class="head">{{'step.head'|translate}}</H2>
    <p [innerHTML]="'step.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Step/25-01-2024/25-01-2024-1.png" alt="" width="500" height="600">
        <img src="assets/components/Step/25-01-2024/25-01-2024-2.png" alt="" width="800" height="600">
    </div>
    <img src="assets/components/123.png" alt="" width="1500" height="50">
    <p [innerHTML]="'step.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Step/21-12-2023/21-12-2023-1.png" alt="" width="800" height="600">
        <img src="assets/components/Step/21-12-2023/21-12-2023-2.png" alt="" width="500" height="600">
    </div>
    <img src="assets/components/123.png" alt="" width="1500" height="50">
    <p [innerHTML]="'step.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Step/23-11-2023/23-11-2023-1.jpg" alt="" width="700" height="500">
        <img src="assets/components/Step/23-11-2023/23-11-2023-2.jpg" alt="" width="500" height="500">
        <img src="assets/components/Step/23-11-2023/23-11-2023-3.jpg" alt="" width="500" height="500">
    </div>
    <img src="assets/components/123.png" alt="" width="1500" height="50">
    <p [innerHTML]="'step.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Step/26-10-2023/26-10-2023-1.jpg" alt="" width="500" height="600">
        <img src="assets/components/Step/26-10-2023/26-10-2023-2.jpg" alt="" width="500" height="600">
        <img src="assets/components/Step/26-10-2023/26-10-2023-3.jpg" alt="" width="500" height="600">
    </div>
    <img src="assets/components/123.png" alt="" width="1500" height="50">
    <p [innerHTML]="'step.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Step/28-09-2023/28-09-2023-1.jpg" alt="" width="500" height="600">
        <img src="assets/components/Step/28-09-2023/28-09-2023-2.jpg" alt="" width="400" height="600">
        <img src="assets/components/Step/28-09-2023/28-09-2023-3.jpg" alt="" width="500" height="600">
    </div>
</div>  
<docs-table [translations]="translations"></docs-table> 
