import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-innovation',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './innovation.component.html',
  styleUrls: ['./innovation.component.sass']
})
export class InnovationComponent {
  translations: string = 'innovation';
}
