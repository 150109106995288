import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-disabled',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './disabled.component.html',
  styleUrls: ['./disabled.component.sass']
})
export class DisabledComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'disabled';
text: any;
}
