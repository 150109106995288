<div class="slider">
  <div>
    <div (click)="goToPrevious()" class="leftArrow">❰</div>
    <div (click)="goToNext()" class="rightArrow">❱</div>
  </div>
  <div
    class="slide"
  >
    <img [src]="getCurrentSlideUrl()" alt="">
  </div>
  <div class="dotsContainer">
    <div
      *ngFor="let slide of slides; let slideIndex = index"
      class="dot"
      [class.active]="slideIndex === currentIndex"
      (click)="goToSlide(slideIndex)"
    >
      ●
    </div>
  </div>
</div>
