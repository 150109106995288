import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Partner } from 'src/app/model/partner';

@Component({
  selector: 'partners-carousel-component',
  templateUrl: './partners-carousel.component.html',
  styleUrls: ['./partners-carousel.component.sass']
})
export class PartnersCarouselComponent {
  partners: Partner[]
  @ViewChild('next') nextBtn: ElementRef;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.loadData();
    });
  }

  ngOnInit(): void {
    setInterval(() => {
      this.clickButton();
    }, 3000);
  }

  clickButton() {
    // Вызов события клика на кнопке
    if (this.nextBtn) {
      this.nextBtn.nativeElement.click();
    }
  }

  loadData() {
      this.translate.get('main-page.partners.list').subscribe((res: any) => {
        this.partners = res;
      });
  }
}
