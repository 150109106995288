<div class="cont">
    <H2 class="head">{{'monitoring.head'|translate}}</H2>
    <p class="head1" [innerHTML]="'monitoring.text' | translate"></p>
    <p class="head1" [innerHTML]="'monitoring.text1' | translate"></p>
    <p class="head2" [innerHTML]="'monitoring.text2' | translate"></p>
    <div class="table">
      <p class="head1" [innerHTML]="'monitoring.text8' | translate"></p>
      <table>
        <tbody>
          <tr>
            <td rowspan=3><strong>Направление специальности(ей), квалификации(й)</strong></td>
            <td colspan=2><strong>Контрольные цифры приема</strong></td>
            <td colspan=4><strong>Подано заявлений от поступающих</strong></td>
            <td rowspan=3><strong>Форма вступительных испытаний</strong></td>
          </tr>
          <tr>
            <td rowspan=2>всего</td>
            <td rowspan=2>из них на условиях целевой подготовки</td>
            <td rowspan=2>всего</td>
            <td colspan=2>из них</td>
            <td rowspan=2>значение среднего балла документа об образовании  (минимальное – максимальное)</td>
          </tr>
          <tr>
            <td>на условиях целевой подготовки</td>
            <td>имеют льготы при зачислении</td>
          </tr>
          <tr>
            <td>
              <br>4-02-0714-01 Обработка деталей на металлорежущих станках
              <br>4-02-0714-02 Эксплуатация и наладка автоматизированного оборудования машиностроительного производства
              <br><i>Квалификации:</i>
              <br> 4-02-0714-01-04 Фрезеровщик 3 разряда
              <br>4-02-0714-02-02 Оператор станков с программным управлением 4 разряда
            </td>
            <td>30</td>
            <td>13</td>
            <td class="head2" [innerHTML]="'34'"></td>
            <td>13</td>
            <td></td>
            <td>6,4  (5,6 - 7,2)</td>
            <td>Конкурс среднего балла документа об образовании</td>
          </tr>
          <tr>
            <td>4-02-0714-03 Сборочные и ремонтные работы
              <br>4-02-0715-01 Эксплуатация, ремонт и обслуживание автомобилей
              <br> <i>Квалификации:</i>
              <br>4-02-0714-03-02 Слесарь механосборочных работ 4 разряда
              <br> 4-02-0715-01-03 Слесарь по ремонту автомобилей 3 разряда
            </td>
            <td>60</td>
            <td>4</td>
            <td class="head2" [innerHTML]="'68'"></td>
            <td class="head2" [innerHTML]="'5'"></td>
            <td></td>
            <td>6,2  (4,5 - 7,3) </td>
            <td>Конкурс среднего балла документа об образовании</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p class="head1" [innerHTML]="'monitoring.text4' | translate"></p>
    <p class="head2" [innerHTML]="'monitoring.text5' | translate"></p>

    <div class="table">
      <p class="head1" [innerHTML]="'monitoring.text6' | translate"></p>
      <table>
        <tbody>
          <tr>
            <td rowspan="3"><strong>Специальность</strong></td>
            <td colspan="3"><strong>План приема</strong></td>
            <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
            <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
            <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
          </tr>
          <tr>
            <td colspan="2">за счет средств бюджета</td>
            <td rowspan="2">на платной основе</td>
          </tr>
          <tr>
            <td>всего</td>
            <td>в том числе на условиях целевой подготовки</td>
            <td>4.0-4.5</td>
            <td>4.6-5.0</td>
            <td>5.1-5.5</td>
            <td>5.6-6.0</td>
            <td>6.1-6.5</td>
            <td>6.6-7.0</td>
            <td>7.1-7.5</td>
            <td>7.6-8.0</td>
            <td>8.1-8.5</td>
            <td>8.6-9.0</td>
            <td>9.1-9.5</td>
            <td>9.6-10.0</td>
          </tr>
          <tr>
            <td>5-04-0714-17 Эксплуатация мехатронных систем промышленного оборудования <br> Квалификация: <br> Техник-мехатроник</td>
            <td>26</td>
            <td>2</td>
            <td>-</td>
            <td rowspan="2"></td>
            <td rowspan="2"></td>
            <td rowspan="2">3</td>
            <td rowspan="2">2</td>
            <td rowspan="2">1</td>
            <td rowspan="2">8</td>
            <td rowspan="2">10</td>
            <td rowspan="2">12</td>
            <td rowspan="2">1</td>
            <td rowspan="2"></td>
            <td rowspan="2"></td>
            <td rowspan="2"></td>
            <td rowspan="2" class="head2" [innerHTML]="'37'"></td>
            <td rowspan="5">Конкурс среднего балла документа об образовании</td>
          </tr>
          <tr>
            <td colspan="4">Подано заявлений от абитуриентов
              <br> из них:</td>
          </tr>
          <tr>
            <td colspan="4">на условиях целевой подготовки</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>2</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>2</td>
          </tr>
          <tr>
            <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colspan="4">на платной основе</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    
  <div class="table">
  <p class="head1" [innerHTML]="'monitoring.text7' | translate"></p>
    <table>
      <tbody>
        <tr>
          <td rowspan="3"><strong>Специальность</strong></td>
          <td colspan="3"><strong>План приема</strong></td>
          <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
          <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
          <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
        </tr>
        <tr>
          <td colspan="2">за счет средств бюджета</td>
          <td rowspan="2">на платной основе</td>
        </tr>
        <tr>
          <td>всего</td>
          <td>в том числе на условиях целевой подготовки</td>
          <td>4.0-4.5</td>
          <td>4.6-5.0</td>
          <td>5.1-5.5</td>
          <td>5.6-6.0</td>
          <td>6.1-6.5</td>
          <td>6.6-7.0</td>
          <td>7.1-7.5</td>
          <td>7.6-8.0</td>
          <td>8.1-8.5</td>
          <td>8.6-9.0</td>
          <td>9.1-9.5</td>
          <td>9.6-10.0</td>
        </tr>
        <tr>
          <td>5-04-0714-17 Эксплуатация мехатронных систем промышленного оборудования <br> Квалификация: <br> Техник-мехатроник</td>
          <td>28</td>
          <td>4</td>
          <td>-</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2">1</td>
          <td rowspan="2">4</td>
          <td rowspan="2">1</td>
          <td rowspan="2">4</td>
          <td rowspan="2">26</td>
          <td rowspan="2">4</td>
          <td rowspan="2">1</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2" class="head2" [innerHTML]="'41'"></td>
          <td rowspan="5">Конкурс среднего балла документа об образовании</td>
        </tr>
        <tr>
          <td colspan="4">Подано заявлений от абитуриентов
            <br> из них:</td>
        </tr>
        <tr>
          <td colspan="4">на условиях целевой подготовки</td>
          <td></td>
          <td></td>
          <td>1</td>
          <td>2</td>
          <td></td>
          <td>1</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>4</td>
        </tr>
        <tr>
          <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
        </tr>
        <tr>
          <td colspan="4">на платной основе</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
 
  <div class="table">
  <p class="head1" [innerHTML]="'monitoring.text7' | translate"></p>
    <table> 
      <tbody>
        <tr>
          <td rowspan="3"><strong>Специальность</strong></td>
          <td colspan="3"><strong>План приема</strong></td>
          <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
          <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
          <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
        </tr>
        <tr>
          <td colspan="2">за счет средств бюджета</td>
          <td rowspan="2">на платной основе</td>
        </tr>
        <tr>
          <td>всего</td>
          <td>в том числе на условиях целевой подготовки</td>
          <td>4.0-4.5</td>
          <td>4.6-5.0</td>
          <td>5.1-5.5</td>
          <td>5.6-6.0</td>
          <td>6.1-6.5</td>
          <td>6.6-7.0</td>
          <td>7.1-7.5</td>
          <td>7.6-8.0</td>
          <td>8.1-8.5</td>
          <td>8.6-9.0</td>
          <td>9.1-9.5</td>
          <td>9.6-10.0</td>
        </tr>
        <tr>
          <td>4-03-0715-01 Обслуживание и ремонт автомобилей <br> Квалификация: <br> 4-03-0715-01-01 Автомеханик 5  разряда</td>
          <td>28</td>
          <td>-</td>
          <td>-</td>
          <td rowspan="2"></td>
          <td rowspan="2">1</td>
          <td rowspan="2">2</td>
          <td rowspan="2">4</td>
          <td rowspan="2">1</td>
          <td rowspan="2">4</td>
          <td rowspan="2">4</td>
          <td rowspan="2">14</td>
          <td rowspan="2">5</td>
          <td rowspan="2">1</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2" class="head2" [innerHTML]="'36'"></td>
          <td rowspan="5">Конкурс среднего балла документа об образовании</td>
        </tr>
        <tr>
          <td colspan="4">Подано заявлений от абитуриентов<br> из них:</td>
        </tr>
        <tr>
          <td colspan="4">на условиях целевой подготовки</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
          <td></td>
          <td>1</td>
          <td>2</td>
          <td>2</td>
          <td></td>
          <td></td>
          <td>1</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>6</td>
        </tr>
        <tr>
          <td colspan="4">на платной основе</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>


  <div class="table">
    <p class="head1" [innerHTML]="'monitoring.text9' | translate"></p>
    <table> 
      <tbody>
        <tr>
          <td rowspan="3"><strong>Специальность</strong></td>
          <td colspan="3"><strong>План приема</strong></td>
          <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
          <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
          <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
        </tr>
        <tr>
          <td colspan="2">за счет средств бюджета</td>
          <td rowspan="2">на платной основе</td>
        </tr>
        <tr>
          <td>всего</td>
          <td>в том числе на условиях целевой подготовки</td>
          <td>4.0-4.5</td>
          <td>4.6-5.0</td>
          <td>5.1-5.5</td>
          <td>5.6-6.0</td>
          <td>6.1-6.5</td>
          <td>6.6-7.0</td>
          <td>7.1-7.5</td>
          <td>7.6-8.0</td>
          <td>8.1-8.5</td>
          <td>8.6-9.0</td>
          <td>9.1-9.5</td>
          <td>9.6-10.0</td>
        </tr>
        <tr>
          <td>5-04-0412-02 Операционная деятельность в логистике <br> Квалификация: <br>  Операционный логист</td>
          <td>28</td>
          <td>-</td>
          <td>-</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2">1</td>
          <td rowspan="2"></td>
          <td rowspan="2">2</td>
          <td rowspan="2">2</td>
          <td rowspan="2">16</td>
          <td rowspan="2">16</td>
          <td rowspan="2"></td>
          <td rowspan="2" class="head2" [innerHTML]="'37'"></td>
          <td rowspan="5">Конкурс среднего балла документа об образовании</td>
        </tr>
        <tr>
          <td colspan="4">Подано заявлений от абитуриентов  <br> из них:</td>
        </tr>
        <tr>
          <td colspan="4">на условиях целевой подготовки</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
          <td></td>
          <td>2</td>
          <td></td>
          <td>1</td>
          <td></td>
          <td></td>
          <td>4</td>
        </tr>
        <tr>
          <td colspan="4">на платной основе</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

 
  <div class="table">
    <p class="head1" [innerHTML]="'monitoring.text7' | translate"></p>
    <table>  
      <tbody>
        <tr>
          <td rowspan="3"><strong>Специальность</strong></td>
          <td colspan="3"><strong>План приема</strong></td>
          <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
          <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
          <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
        </tr>
        <tr>
          <td colspan="2">за счет средств бюджета</td>
          <td rowspan="2">на платной основе</td>
        </tr>
        <tr>
          <td>всего</td>
          <td>в том числе на условиях целевой подготовки</td>
          <td>4.0-4.5</td>
          <td>4.6-5.0</td>
          <td>5.1-5.5</td>
          <td>5.6-6.0</td>
          <td>6.1-6.5</td>
          <td>6.6-7.0</td>
          <td>7.1-7.5</td>
          <td>7.6-8.0</td>
          <td>8.1-8.5</td>
          <td>8.6-9.0</td>
          <td>9.1-9.5</td>
          <td>9.6-10.0</td>
        </tr>
        <tr>
          <td>5-04-0714-03 Техническая эксплуатация технологического оборудования машиностроительного производства <br> Квалификация:<br> Техник-механик</td>
          <td>54</td>
          <td>1</td>
          <td>-</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2">9</td>
          <td rowspan="2">29</td>
          <td rowspan="2">34</td>
          <td rowspan="2">12</td>
          <td rowspan="2">1</td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2"></td>
          <td rowspan="2" class="head2" [innerHTML]="'85'"></td>
          <td rowspan="5">Конкурс среднего балла документа об образовании</td>
        </tr>
        <tr>
          <td colspan="4">Подано заявлений от абитуриентов
            <br> из них:
          </td>
        </tr>
        <tr>
          <td colspan="4">на условиях целевой подготовки</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
        </tr>
        <tr>
          <td colspan="4">на платной основе</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table">
    <p class="head1" [innerHTML]="'monitoring.text7' | translate"></p>  
    <table>
    <tbody>
      <tr>
        <td rowspan="3"><strong>Специальность</strong></td>
        <td colspan="3"><strong>План приема</strong></td>
        <td colspan="12" rowspan="2"><strong>Средний балл документа об образовании</strong></td>
        <td rowspan="3"><strong>Подано заявлений, всего</strong></td>
        <td rowspan="3"><strong>Форма вступительных испытаний</strong></td>
      </tr>
      <tr>
        <td colspan="2">за счет средств бюджета</td>
        <td rowspan="2">на платной основе</td>
      </tr>
      <tr>
        <td>всего</td>
        <td>в том числе на условиях целевой подготовки</td>
        <td>4.0-4.5</td>
        <td>4.6-5.0</td>
        <td>5.1-5.5</td>
        <td>5.6-6.0</td>
        <td>6.1-6.5</td>
        <td>6.6-7.0</td>
        <td>7.1-7.5</td>
        <td>7.6-8.0</td>
        <td>8.1-8.5</td>
        <td>8.6-9.0</td>
        <td>9.1-9.5</td>
        <td>9.6-10.0</td>
      </tr>
      <tr>
        <td>5-04-0714-03 Техническая эксплуатация технологического оборудования машиностроительного производства <br>  Квалификация: <br>  Техник-механик</td>
        <td>54</td>
        <td>6</td>
        <td>-</td>
        <td rowspan="2"></td>
        <td rowspan="2"></td>
        <td rowspan="2"></td>
        <td rowspan="2">2</td>
        <td rowspan="2"></td>
        <td rowspan="2">1</td>
        <td rowspan="2">6</td>
        <td rowspan="2">24</td>
        <td rowspan="2">22</td>
        <td rowspan="2">6</td>
        <td rowspan="2">2</td>
        <td rowspan="2"></td>
        <td rowspan="2" class="head2" [innerHTML]="'63'"></td>
        <td rowspan="5">Конкурс среднего балла документа об образовании</td>
      </tr>
      <tr>
        <td colspan="4">Подано заявлений от абитуриентов
          <br> из них:</td>
      </tr>
        <tr>
        <td colspan="4">на условиях целевой подготовки</td>
        <td></td>
        <td></td>
        <td></td>
        <td>2</td>
        <td></td>
        <td>1</td>
        <td>3</td>
        <td>2</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>8</td>
      </tr>
      <tr>
        <td colspan="4">имеющие льготы на зачисление вне конкурса</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>1</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>1</td>
      </tr>
      <tr>
        <td colspan="4">на платной основе</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
<docs-table [translations]="translations"></docs-table>