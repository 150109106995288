import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.sass']
})
export class OrdersComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'orders';
}
