import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-cooperation',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './cooperation.component.html',
  styleUrls: ['./cooperation.component.sass']
})
export class CooperationComponent {
  translations: string = 'cooperation';
}
