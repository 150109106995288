import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from '../../docs-table/docs-table.component';

@Component({
  selector: 'app-industrial-training',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './industrial-training.component.html',
  styleUrls: ['./industrial-training.component.sass']
})
export class IndustrialTrainingComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'industrial-training';
}
