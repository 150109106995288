import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroBannerComponent } from './intro-banner.component';
import { TranslateModule } from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import { InnerCarouselComponent } from '../../inner-carousel/inner-carousel.component';

@NgModule({
  declarations: [IntroBannerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    InnerCarouselComponent
  ],
  exports:[IntroBannerComponent]
})
export class IntroBannerModule { }
