<div class="partners-wrapper">
  <div class="intro">
    <h2>{{'main-page.partners.name' | translate}}</h2>
  </div>
  <div class="cont">
    <div class="content">
      <div class="slider-wrapper">
        <div class="slider-main">
          <div class="items" *ngFor="let item of partners">
            <div class="item">
              <a href="{{item.siteUrl}}" target="_blank">
                <img src="{{item.imgUrl}}" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="button-area">
          <button class="prev" prev>❰</button>
          <button class="next" #next next>❱</button>
        </div>
      </div>
    </div>
  </div>
</div>
