<div class="cont">
    <H2 class="head">{{'spps-ych.head'|translate}}</H2>

    <div class="display-row">
        <img src="assets/components/SPPS/spps1.png" alt="Фотография" width="600" height="300">
    </div>
<docs-table [translations]="translations"></docs-table> 
    <p class="head1" [innerHTML]="'spps-ych.text' | translate"></p>
    <p class="head" [innerHTML]="'spps-ych.text1' | translate"></p>
    <p class="head2" [innerHTML]="'spps-ych.text2' | translate"></p>
    <p class="head" [innerHTML]="'spps-ych.text3' | translate"></p>
</div> 
