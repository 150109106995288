import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-volunteer-movement',
  standalone: true,
  imports: [CommonModule, DocsTableComponent,TranslateModule],
  templateUrl: './volunteer-movement.component.html',
  styleUrls: ['./volunteer-movement.component.sass']
})
export class VolunteerMovementComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'volunteer-movement';
}
