import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-yo',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './yo.component.html',
  styleUrls: ['./yo.component.sass']
})
export class YOComponent {
  translations: string = 'yo';
}
