import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsTableComponent } from '../../../docs-table/docs-table.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-anti-corruption',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './anti-corruption.component.html',
  styleUrls: ['./anti-corruption.component.sass']
})
export class AntiCorruptionComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'anti-corruption';
}
