<div class="cont">
    <H2 class="head">{{'oi-ra.head'|translate}}</H2>
    
</div>
<docs-table [translations]="translations"></docs-table>

<div class="cont">
    <div class="table">
        <p class="head1" [innerHTML]="'omp.text' | translate"></p>
        <table >

            <tbody>
                <tr>
                    <td width=70>№ п/п</td>
                    <td width=1000>Специальность</td>
                    <td width=150>Курс</td>
                    <td width=300>Количество вакантных мест</td>
                    <td>Количество поданных заявлений</td>
                </tr>
                <tr>
                    <td>1.</td>
                    <td>2-37&nbsp;01&nbsp;51 «Автосервис»</td>
                    <td>IV</td>
                    <td>1</td>
                    <td>-</td>
                </tr>
            </tbody>

        </table>
    </div>
</div>

<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/Avtomex.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/log.png" alt="Фотография" width="700" height="1000">
    </div>
</div>
