<div class="cont">
    <H2 class="head">{{'pto.head'|translate}}</H2>
    
</div>
<docs-table [translations]="translations"></docs-table>
<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/Slesar.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/Oper.png" alt="Фотография" width="700" height="1000">
    </div>
</div>