<div class="cont">
    <H2 class="head">{{'professional.head'|translate}}</H2>

    <docs-table [translations]="translations"></docs-table> 

    <p class="head2" [innerHTML]="'professional.text' | translate"></p>

    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/1.jpg" alt="Фотография" width="600" height="250">
        </div>
    </div>

    <p class="head4" [innerHTML]="'professional.text1' | translate"></p>
    <p class="head" [innerHTML]="'professional.text2' | translate"></p>
  
    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/2.jpg" alt="Фотография" width="400" height="250">
        </div>
    </div>
  
    <p class="head4" [innerHTML]="'professional.text3' | translate"></p>
    <p class="head" [innerHTML]="'professional.text4' | translate"></p>
  
    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/3.jpg" alt="Фотография" width="400" height="250">
        </div>
    </div>
  
    <p class="head" [innerHTML]="'professional.text5' | translate"></p>
  
    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/4.jpg" alt="Фотография" width="400" height="250">
        </div>
    </div>
  
    <p class="head" [innerHTML]="'professional.text6' | translate"></p>

    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/5.jpg" alt="Фотография" width="400" height="250">
        </div>
    </div>

    <p class="head4" [innerHTML]="'professional.text7' | translate"></p>
    <p class="head" [innerHTML]="'professional.text8' | translate"></p>

    <div class="cont">

        <div class="display-row">
            <img src="assets/components/professional/6.jpg" alt="Фотография" width="400" height="250">
        </div>
    </div>

</div>  
<docs-table [translations]="translations"></docs-table> 
