<div class="cont">
    <H2 class="head">{{'competencecenter.head'|translate}}</H2>
    

<docs-table [translations]="translations"></docs-table>
<p class="head1" [innerHTML]="'competencecenter.text' | translate"></p>
<div class="display-row">    
    <img src="assets/components/centrcomp/avto.jpg" alt="Фотография" width="700" height="600">
    <img src="assets/components/centrcomp/stanok.jpg" alt="Фотография" width="700" height="600">
</div>
<p class="head1" [innerHTML]="'competencecenter.text1' | translate"></p>
</div>