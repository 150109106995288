import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[prev]'
})
export class PrevDirective {

  constructor(private el: ElementRef) { }

  @HostListener('click')
  prevFunc() {
    var elm = this.el.nativeElement.parentElement.parentElement.children[0]
    console.log(elm)
    var item = elm.getElementsByClassName('items')
    elm.prepend(item[item.length - 1])
  }

}
