import { Mail } from './../model/mail';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  backendUrl = 'someurl';
  consultUrl = this.backendUrl + '/api/mail';

  constructor(private http: HttpClient) { }

  sendMail(data: Mail): Observable<any> {
    console.log(data)
    return this.http.post(this.consultUrl, data);
  }
}
