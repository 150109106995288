<div class="cont">
    <H2 class="head">{{'industrial-training.head'|translate}}</H2>
    

<docs-table [translations]="translations"></docs-table>
<p class="head1" [innerHTML]="'industrial-training.text' | translate"></p>
<p class="head1" [innerHTML]="'industrial-training.text1' | translate"></p>
<div class="display-row">
    <a href="https://solidauto.by/autoservice/ooo-sto-tl-trejd/" target="_blank"><img src="assets/components/bazovie org/solid auto service.png" alt="" class="img-fluid" width="300" height="70"></a>
    <a href="https://peleng.by/" target="_blank"><img src="assets/components/bazovie org/Peleng.png" alt="" class="img-fluid" width="300" height="100"></a>
    <a href="https://mgw.by/" target="_blank"><img src="assets/components/bazovie org/MZH.png" alt="" class="img-fluid" width="300" height="90"></a>
    <a href="https://www.mzkt.by/" target="_blank"><img src="assets/components/bazovie org/volat.png" alt="" class="img-fluid" width="300" height="100"></a>
    <a href="https://leangroup-by.com/" target="_blank"><img src="assets/components/bazovie org/leangroup.png" alt="" class="img-fluid" width="300" height="100"></a>
</div>
<div class="display-row">
    <a href="https://www.stprogress.by/" target="_blank"><img src="assets/components/bazovie org/stprogress.png" alt="" class="img-fluid" width="300" height="90"></a>
    <a href="https://niva.by/enterprises/upp-niva-romanovicha-s-g-soligorskij-rajon/" target="_blank"><img src="assets/components/bazovie org/niva.png" alt="" class="img-fluid" width="300" height="100"></a>
    <a href="https://amkodor.by/" target="_blank"><img src="assets/components/bazovie org/amcador.png" alt="" class="img-fluid" width="300" height="100"></a>
    <a href="https://www.belarus-tractor.com/" target="_blank"><img src="assets/components/bazovie org/MTZ.png" alt="" class="img-fluid" width="300" height="100"></a>
    <a href="https://www.smarton.by/" target="_blank"><img src="assets/components/bazovie org/smarton.png" alt="" class="img-fluid" width="300" height="100"></a>
</div>
<div class="display-row">
    <a href="https://belomo.by/company/brands/vavilov/" target="_blank"><img src="assets/components/bazovie org/belomo.png" alt="" class="img-fluid" width="150" height="70"></a>
</div>
</div>
