import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-schedule-ripo',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './schedule-ripo.component.html',
  styleUrls: ['./schedule-ripo.component.sass']
})
export class ScheduleRIPOComponent {
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'schedule-ripo';
}
