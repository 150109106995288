import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroBannerModule } from '../main-page/intro-banner/intro-banner.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GDocument } from 'src/app/model/document';

@Component({
  selector: 'docs-table',
  standalone: true,
  imports: [CommonModule, IntroBannerModule, TranslateModule],
  templateUrl: './docs-table.component.html',
  styleUrls: ['./docs-table.component.sass']
})
export class DocsTableComponent {
  @Input() bannerImage: string;
  @Input() translations: string;
  documents: GDocument[];

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
  }

  ngOnInit() {
    this.loadTranslations();
  }

  loadTranslations() {
    if (this.translations) {
      this.translate.get("docs." + this.translations).subscribe((res: any) => {
        this.documents = res;
      });
    } else {
      console.error('Translations key is not set');
    }
  }
}
