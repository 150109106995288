<div class="cont">
    <H2 class="head">{{'omp.head'|translate}}</H2>
    
</div>
<docs-table [translations]="translations"></docs-table>

<div class="cont">
    <div class="table">
        <p class="head1" [innerHTML]="'omp.text' | translate"></p>
        <table >

            <tbody>
                <tr>
                    <td width=70>№    п/п</td>
                    <td>Специальность</td>
                    <td width=300>Курс</td>
                    <td>Количество вакантных мест</td>
                    <td>Количество поданных заявлений</td>
                </tr>
                <tr>
                    <td>1.</td>
                    <td>2-36 01 03 «Технологическое оборудование машиностроительного производства»</td>
                    <td>III</td>
                    <td>3</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>2-36 01 53 «Техническая эксплуатация оборудования»</td>
                    <td>IV</td>
                    <td>2</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td>5-04-0714-03 «Техническая эксплуатация технологического оборудования машиностроительного производства»</td>
                    <td>II</td>
                    <td>3</td>
                    <td>-</td>
                </tr>
            </tbody>

        </table>
    </div>
</div>
<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/naladchik.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/tex-mexKond.png" alt="Фотография" width="700" height="1000">
    </div>
</div>