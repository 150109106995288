import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main-page/main/main.component';
import { AntiCorruptionComponent } from './components/navigation/about-college/anti-corruption/anti-corruption.component';
import { AdministrationComponent } from './components/navigation/about-college/administration/administration.component';
import { SafetyComponent } from './components/navigation/about-college/safety/safety.component';
import { DisabledComponent } from './components/navigation/about-college/disabled/disabled.component';
import { PersonalComponent } from './components/navigation/about-college/personal/personal.component';
import { LocalActsComponent } from './components/navigation/about-college/local-acts/local-acts.component';
import { TTrainingComponent } from './components/navigation/trainees/t-training/t-training.component';
import { RegulationsComponent } from './components/navigation/trainees/regulations/regulations.component';
import { SpecialtiesComponent } from './components/navigation/trainees/specialties/specialties.component';
import { SelfManagementComponent } from './components/navigation/upbringing/self-management/self-management.component';
import { LegalCornerComponent } from './components/navigation/upbringing/legal-corner/legal-corner.component';
import { ContactsApplicantComponent } from './components/navigation/trainees/contacts-applicant/contacts-applicant.component';
import { StepComponent } from './components/navigation/upbringing/step/step.component';
import { ABCsafetyComponent } from './components/navigation/upbringing/abcsafety/abcsafety.component';
import { VolunteerMovementComponent } from './components/navigation/upbringing/volunteer-movement/volunteer-movement.component';
import { TravelComponent } from './components/navigation/upbringing/travel/travel.component';
import { ScheduleKSTMIAComponent } from './components/navigation/singlewindow/schedule-kstmia/schedule-kstmia.component';
import { ScheduleRIPOComponent } from './components/navigation/singlewindow/schedule-ripo/schedule-ripo.component';
import { DocumentationComponent } from './components/navigation/singlewindow/documentation/documentation.component';
import { PaymentComponent } from './components/navigation/singlewindow/payment/payment.component';
import { CertificatesComponent } from './components/navigation/singlewindow/certificates/certificates.component';
import { ContactsKSTMIAComponent } from './components/navigation/singlewindow/contacts-kstmia/contacts-kstmia.component';
import { MSPOComponent } from './components/navigation/mspo/mspo.component';
import { OMPComponent } from './components/navigation/omp/omp.component';
import { OiRAComponent } from './components/navigation/oi-ra/oi-ra.component';
import { PTOComponent } from './components/navigation/pto/pto.component';
import { CompetencecenterComponent } from './components/navigation/competencecenter/competencecenter.component';
import { HostelComponent } from './components/navigation/hostel/hostel.component';
import { LibraryComponent } from './components/navigation/library/library.component';
import { ProfSotrComponent } from './components/navigation/prof-sotr/prof-sotr.component';
import { ProfStydComponent } from './components/navigation/prof-styd/prof-styd.component';
import { IndustrialTrainingComponent } from './components/navigation/industrial-training/industrial-training.component';
import { SppsYchComponent } from './components/navigation/upbringing/spps-ych/spps-ych.component';
import { SppsRodComponent } from './components/navigation/upbringing/spps-rod/spps-rod.component';
import { SppsPedComponent } from './components/navigation/upbringing/spps-ped/spps-ped.component';
import { SppsKyrComponent } from './components/navigation/upbringing/spps-kyr/spps-kyr.component';
import { HelpComponent } from './components/navigation/upbringing/help/help.component';
import { MonitoringComponent } from './components/navigation/trainees/monitoring/monitoring.component';
import { GodkachestvaComponent } from './components/navigation/godkachestva/godkachestva.component';
import { OrdersComponent } from './components/navigation/side-menu/orders/orders.component';
import { ProvisionsComponent } from './components/navigation/side-menu/provisions/provisions.component';
import { LettersComponent } from './components/navigation/side-menu/letters/letters.component';
import { SeminarsComponent } from './components/navigation/side-menu/seminars/seminars.component';
import { OpenclassesComponent } from './components/navigation/side-menu/openclasses/openclasses.component';
import { InnovationComponent } from './components/navigation/side-menu/innovation/innovation.component';
import { YOComponent } from './components/navigation/side-menu/yo/yo.component';
import { CooperationComponent } from './components/navigation/side-menu/cooperation/cooperation.component';
import { SurveyComponent } from './components/navigation/side-menu/survey/survey.component';
import { AnnouncementComponent } from './components/navigation/trainees/announcement/announcement.component';
import { NewsComponent } from './components/navigation/news/news.component';
import { ProfessionalComponent } from './components/navigation/trainees/professional/professional.component';



const routes: Routes = [
  {path:"", component: MainComponent},
  {path:"about-college", children:[
    {path: "administration", component: AdministrationComponent},
    {path:"anti-corruption", component: AntiCorruptionComponent},
    {path:"safety", component: SafetyComponent},
    {path:"disabled", component: DisabledComponent},
    {path:"personal", component: PersonalComponent},
    {path:"local-acts", component: LocalActsComponent},
    {path:"mspo", component: MSPOComponent},
    {path:"omp", component: OMPComponent},
    {path:"oi-ra", component: OiRAComponent},
    {path:"pto", component: PTOComponent},
    {path:"competencecenter", component: CompetencecenterComponent},
    {path:"hostel", component: HostelComponent},
    {path:"library", component: LibraryComponent},
    {path:"prof-sotr", component: ProfSotrComponent},
    {path:"prof-styd", component: ProfStydComponent},
    {path:"industrial-training", component: IndustrialTrainingComponent},
    {path:"godkachestva", component: GodkachestvaComponent},
    {path:"orders", component: OrdersComponent},
    {path:"provisions", component: ProvisionsComponent},
    {path:"letters", component: LettersComponent},
    {path:"seminars", component: SeminarsComponent},
    {path:"openclasses", component: OpenclassesComponent},
    {path:"innovation", component: InnovationComponent},
    {path:"yo", component: YOComponent},
    {path:"cooperation", component: CooperationComponent},
    {path:"survey", component: SurveyComponent},
  ]},
  {path:"news", component: NewsComponent},

  {path:"trainees", children:[
    {path: "announcement", component: AnnouncementComponent},
    {path: "t-training", component: TTrainingComponent},
    {path: "regulations", component: RegulationsComponent},
    {path: "specialties", component: SpecialtiesComponent},
    {path: "contacts-applicant", component: ContactsApplicantComponent},
    {path: "monitoring", component: MonitoringComponent},
    {path: "professional", component: ProfessionalComponent},
  ]},

  {path:"students", component: MainComponent},

  {path:"teachers", component: MainComponent},
  
  {path:"upbringing", children:[
    {path: "self-management", component: SelfManagementComponent},
    {path: "legal-corner", component: LegalCornerComponent},
    {path: "step", component: StepComponent},
    {path: "abcsafety", component: ABCsafetyComponent},
    {path: "volunteer-movement", component: VolunteerMovementComponent},
    {path: "travel", component: TravelComponent},
    {path: "spps-ych", component: SppsYchComponent},
    {path: "spps-rod", component: SppsRodComponent},
    {path: "spps-ped", component: SppsPedComponent},
    {path: "spps-kyr", component: SppsKyrComponent},
    {path: "help", component: HelpComponent},
  ]},

  {path:"services", component: MainComponent},

  {path:"singlewindow", children:[
    {path: "schedule-kstmia", component: ScheduleKSTMIAComponent},
    {path: "schedule-ripo", component: ScheduleRIPOComponent},
    {path: "documentation", component: DocumentationComponent},
    {path: "payment", component: PaymentComponent},
    {path: "certificates", component: CertificatesComponent},
    {path: "contacts-kstmia", component: ContactsKSTMIAComponent},
  ]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
