<div class="cont">
    <H2 class="head">{{'prof-styd.head'|translate}}</H2>
    

<docs-table [translations]="translations"></docs-table>
<p class="head1" [innerHTML]="'prof-styd.text' | translate"></p>
<p class="head1" [innerHTML]="'prof-styd.text1' | translate"></p>





<p [innerHTML]="'news.text3' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/25-04-2024-1.jpg" alt="Фотография" width="600" height="500">
    <img src="assets/components/prof-styd/25-04-2024-2.jpg" alt="Фотография" width="400" height="500">
    <img src="assets/components/prof-styd/25-04-2024-3.jpg" alt="Фотография" width="400" height="500">
    <img src="assets/components/prof-styd/25-04-2024-4.jpg" alt="Фотография" width="400" height="500">
</div>

<p [innerHTML]="'news.text2' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/16-04-2024-1.jpg" alt="Фотография" width="600" height="500">
    <img src="assets/components/prof-styd/16-04-2024-2.jpg" alt="Фотография" width="600" height="500">
    <img src="assets/components/prof-styd/16-04-2024-3.jpg" alt="Фотография" width="600" height="500">
</div>
</div>