<div class="cont">
    <H2 class="head">{{'schedule-ripo.head'|translate}}</H2>

<docs-table [translations]="translations"></docs-table>
<div class="table">
    <p class="head1" [innerHTML]="'schedule-ripo.text' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td width="25%" class="head1">ДОЛЖНОСТЬ</td>
                <td width="25%" class="head1">Ф.И.О.</td>
                <td width="10%" class="head1">№ КАБ.</td>
                <td width="10%" class="head1">ТЕЛЕФОН</td>
                <td width="10%" class="head1">ДЕНЬ ПРИЕМА</td>
                <td width="25%" class="head1">ВРЕМЯ ПРИЕМА</td>
                <td width="25%" class="head1">E-MAIL</td>
            </tr>
            <tr>
                <td width="25%">Ректор</td>
                <td>ГОЛУБОВСКИЙ ВАЛЕРИЙ НИКОЛАЕВИЧ </td>
                <td>306</td>
                <td>263 00 01</td>
                <td>вторник</td>
                <td>8.00-14.00 </td>
                <td>rector@ripo.by</td>
            </tr>
            <tr>
                <td width="25%">Первый проректор</td>
                <td>СЫЧЁВА ЮЛИЯ СЕРГЕЕВНА</td>
                <td>307</td>
                <td>373 50 21</td>
                <td>понедельник</td>
                <td>8.00-14.00 </td>
                <td>sichova@ripo.by </td>
            </tr>
            <tr>
                <td width="25%">Проректор по научнометодической работе</td>
                <td>ПОПОВА ОКСАНА СЕРГЕЕВНА</td>
                <td>209</td>
                <td>373 38 93</td>
                <td>четверг</td>
                <td>14.00-20.00 </td>
                <td>popova@ripo.by</td>
            </tr>
            <tr>
                <td width="25%">Проректор </td>
                <td>БУЛЬКО НИКОЛАЙ САБИНОВИЧ</td>
                <td>301 </td>
                <td>374 11 80</td>
                <td>вторник</td>
                <td>14.00-20.00 </td>
                <td>bulko@ripo.by</td>
            </tr>
            <tr>
                <td width="25%">Проректор по научнометодической работе</td>
                <td>СОКОРОВ ИГОРЬ ОЛЕГОВИЧ</td>
                <td>207</td>
                <td>272 74 46</td>
                <td>вторник</td>
                <td>8.00-14.00 </td>
                <td>sokorov@ripo.by</td>
            </tr>
            <tr>
                <td width="25%">Начальник управления бухучета и финансов</td>
                <td>АКСЕНОВА НАТАЛЬЯ СЕРГЕЕВНА</td>
                <td>308</td>
                <td>272 39 95</td>
                <td>вторник</td>
                <td>8.00-14.00 </td>
                <td>aksenova@ripo.by</td>
            </tr>
        </tbody>
    </table>
</div>
</div>