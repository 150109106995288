<div class="cont">
    <H2 class="head">{{'openclasses.head'|translate}}</H2>
   
    <p [innerHTML]="'openclasses.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/14-02-24/14-02-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/14-02-24/14-02-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/14-02-24/14-02-24-3.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/13-02-24/13-02-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/13-02-24/13-02-24-3.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/13-02-24/13-02-24-4.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/18-01-24/18-01-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/18-01-24/18-01-24-2.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/18-01-24/18-01-24-3.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/12-01-24/12-01-24-1.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/12-01-24/12-01-24-2.png" alt="" width="500" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/27-12-23/27-12-23-1.png" alt="" width="500" height="400">
        <img src="assets/components/otkritoeychebnoezaniatie/27-12-23/27-12-23-2.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/27-12-23/27-12-23-3.png" alt="" width="600" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text5' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/27-11-23/27-11-23-3.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/27-11-23/27-11-23-1.png" alt="" width="400" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/27-11-23/27-11-23-2.png" alt="" width="500" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text6' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/14-11-23/14-11-23-1.png" alt="" width="500" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/14-11-23/14-11-23-2.png" alt="" width="400" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/14-11-23/14-11-23-3.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>

    <p [innerHTML]="'openclasses.text7' | translate"></p>
    <div class="display-row">
        <img src="assets/components/otkritoeychebnoezaniatie/31-10-23/31-10-23-1.png" alt="" width="400" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/31-10-23/31-10-23-2.png" alt="" width="400" height="500">
        <img src="assets/components/otkritoeychebnoezaniatie/31-10-23/31-10-23-3.png" alt="" width="400" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/123.png" alt="" width="1000" height="50">
    </div>
</div>
<docs-table [translations]="translations"></docs-table>
