<div class="cont">
    <H2 class="head">{{'library.head'|translate}}</H2>
    
</div>

<div class="cont">
    <p class="head1" [innerHTML]="'library.text' | translate"></p>
    <docs-table [translations]="translations"></docs-table>
    <div class="display-row">
        <img src="assets/components/Libruary/Мер1.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/Libruary/Мер2.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/Libruary/Мер3.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/Libruary/Мер4.png" alt="Фотография" width="500" height="500">
    </div>
</div>