import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsefulLinksComponent } from './useful-links.component';
import { TranslateModule } from '@ngx-translate/core';
import { PrevLinkDirective } from './prev.directive';
import { NextLinkDirective } from './next.directive';



@NgModule({
  declarations: [UsefulLinksComponent, PrevLinkDirective, NextLinkDirective],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [UsefulLinksComponent]
})
export class UsefulLinksModule { }
