<div class="cont">
    <H2 class="head">{{'news.head'|translate}}</H2>
</div>  
    <docs-table [translations]="translations"></docs-table>
<div class="cont">




    <p class="head1" [innerHTML]="'news.text78' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/03-10-2024-8.png" alt="Фотография" width="600" height="400">
    </div>

    <p [innerHTML]="'news.text77' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/03-10-2024-6.png" alt="Фотография" width="600" height="400">
        <img src="assets/components/News24/03-10-2024-7.png" alt="Фотография" width="600" height="400">
    </div>

    <p [innerHTML]="'news.text76' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/03-10-2024-4.png" alt="Фотография" width="700" height="400">
        <img src="assets/components/News24/03-10-2024-5.png" alt="Фотография" width="700" height="400">
    </div>

    <p [innerHTML]="'news.text75' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/03-10-2024-1.png" alt="Фотография" width="500" height="400">
        <img src="assets/components/News24/03-10-2024-2.png" alt="Фотография" width="600" height="400">
        <img src="assets/components/News24/03-10-2024-3.png" alt="Фотография" width="600" height="400">
    </div>

    <p [innerHTML]="'news.text74' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/02-10-2024-4.png" alt="Фотография" width="300" height="400">
        <img src="assets/components/News24/02-10-2024-5.png" alt="Фотография" width="300" height="400">
        <img src="assets/components/News24/02-10-2024-6.png" alt="Фотография" width="500" height="400">
    </div>

    <p [innerHTML]="'news.text73' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/02-10-2024-1.png" alt="Фотография" width="500" height="400">
        <img src="assets/components/News24/02-10-2024-2.png" alt="Фотография" width="500" height="400">
        <img src="assets/components/News24/02-10-2024-3.png" alt="Фотография" width="500" height="400">
    </div>

    <p [innerHTML]="'news.text72' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/01-10-2024-1.png" alt="Фотография" width="500" height="400">
        <img src="assets/components/News24/01-10-2024-2.png" alt="Фотография" width="500" height="400">
        <img src="assets/components/News24/01-10-2024-3.png" alt="Фотография" width="500" height="400">
    </div>


</div>