import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[next]'
})
export class NextLinkDirective {

  constructor(private el: ElementRef) { }

  @HostListener('click')
  nextFunc() {
    let elm = this.el.nativeElement.parentElement.parentElement.children[0]
    let item = elm.getElementsByClassName('items')
    elm.append(item[0])
  }
}
