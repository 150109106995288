import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-openclasses',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './openclasses.component.html',
  styleUrls: ['./openclasses.component.sass']
})
export class OpenclassesComponent {
  translations: string = 'openclasses';
}
