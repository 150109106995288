<div class="page-wrapper">
  <ng-container *ngIf="bannerImage">
    <div class="banner">
      <img src="{{bannerImage}}" alt="" class="banner-img">
      <h2 class="banner-content">{{'docs.banner'|translate}}</h2>
    </div>
  </ng-container>
    <div class="cont">
      <div class="body">
        <div class="document-wrapper" *ngFor="let gdoc of documents">
          <div class="doc-icon">
            <img src="{{gdoc.iconImg}}" alt="">
          </div>
          <div class="doc-name">
            <a href="{{gdoc.gdocLink}}" target="_blank">{{gdoc.gdocName}}</a>
          </div>
        </div>
      </div>
    </div>
</div>
