<div class="cont">
    <H2 class="head">{{'mspo.head'|translate}}</H2>
    
</div>
<docs-table [translations]="translations"></docs-table>

<div class="cont">
    <div class="table">
        <p class="head1" [innerHTML]="'omp.text' | translate"></p>
        <table >
            <tbody>
                <tr>
                    <td width=70>№ п/п</td>
                    <td width=1000>Специальность</td>
                    <td width=150>Курс</td>
                    <td width=300>Количество вакантных мест</td>
                    <td>Количество поданных заявлений</td>
                </tr>
                <tr>
                    <td>1.</td>
                    <td>5-04-0714-17 «Эксплуатация мехатронных систем промышленного оборудования»</td>
                    <td>II</td>
                    <td>0</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td rowspan="2">2.</td>
                    <td rowspan="2">2-36 01 33 «Эксплуатация мехатронных систем промышленного оборудования»</td>
                    <td>III</td>
                    <td>4</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>IV</td>
                    <td>7</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/tex-mexBacsh.png" alt="Фотография" width="700" height="1000">
    </div>
</div>