import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

@Component({
  selector: 'video-component',
  standalone: true,
  imports: [CommonModule, TranslateModule, CarouselModule, NgxYoutubePlayerModule],
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.sass']
})
export class VideoComponent {
  player: YT.Player
  slides = ["EGEVxNKWHa4", "EcXlGc1SOic", "K5dkjA6Nqr4", "l_0hS7YQYvI"]
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    navSpeed: 700,
    dots: false,
    navText: [ "<", ">" ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true,
  }

}
